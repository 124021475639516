import React from "react";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import loadable from "@loadable/component";

import client from "shared/utilities/client";

import "./App.css";

// Import app dynamically
const Nhl = loadable(() => import("./apps/Nhl"));
const Ahl = loadable(() => import("./apps/Ahl"));

const App: React.FC = () => {
	// Example: /apps/nhl/releases/62/personas/vancouver_canucks/screens/nhl_standings
	// App level routing
	return (
		<ApolloProvider client={client}>
			<BrowserRouter>
				<Switch>
					<Route path="/apps/nhl/releases/:release/personas/:persona/screens/:screen">
						<Nhl />
					</Route>
					<Route path="/apps/ahl/releases/:release/personas/:persona/screens/:screen">
						<Ahl />
					</Route>
				</Switch>
			</BrowserRouter>
		</ApolloProvider>
	);
};

export default App;
