import {
	ApolloClient,
	ApolloLink,
	HttpLink,
	InMemoryCache,
} from "@apollo/client";
import { RestLink } from "apollo-link-rest";

const link = ApolloLink.from([
	new RestLink({
		uri: process.env.REACT_APP_REST_API_URL,
	}),
	new HttpLink({
		uri: process.env.REACT_APP_GRAPHQL_API_URL,
	}),
]);

const cache = new InMemoryCache();

const client = new ApolloClient({
	link,
	cache,
});

export default client;
